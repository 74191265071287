import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import useLocalStorage from 'use-localstorage-hook';
import logo from '../../assets/images/logo_white.svg';
import androidMenu from './assets/pwa/android_menu.svg';
import iosAdd from './assets/pwa/ios_add.svg';
import iosShare from './assets/pwa/ios_share.svg';
import safariIcon from './assets/pwa/safari.svg';
import {
  hasUserCancelledPWA,
  isInStandaloneMode,
  isIOS,
  isSafari,
  isIPad,
  isAndroidDevice,
  isApplicableMobileDevice,
  AppIcon,
} from './PWAUtils';
import { RouterlessModal as Modal } from './Modal';

const IOSInstallInstructions = () => (
  <Typography gutterBottom darker>
    Tap on the <img src={iosShare} alt="" style={{ height: 14 }} /> Share Button and then tap{' '}
    <img src={iosAdd} alt="" style={{ height: 14 }} /> Add to Home Screen.
  </Typography>
);

const AndroidInstallInstructions = () => (
  <Typography gutterBottom darker>
    Tap on the <img src={androidMenu} alt="" style={{ height: 14 }} /> Menu button and then choose Add to Home Screen.
  </Typography>
);

const NonSafariInstallInstructions = () => (
  <Typography gutterBottom darker>
    To get the app, you need to first open View by Ospitek in <img src={safariIcon} alt="" style={{ height: 14 }} />{' '}
    Safari.
  </Typography>
);

const pickInstructions = () => {
  if (isIOS() && isSafari()) {
    return <IOSInstallInstructions />;
  } else if (isAndroidDevice() && !isIOS()) {
    return <AndroidInstallInstructions />;
  } else {
    return <NonSafariInstallInstructions />;
  }
};

export default ({ children }) => {
  const [PWAInfo, setPWAInfo] = useLocalStorage('PWAInfo', { canceledAt: undefined, cancelCount: 0 });
  const userCanceledPWA = hasUserCancelledPWA(PWAInfo);
  const isMobile = isApplicableMobileDevice();
  const standaloneMode = isInStandaloneMode();

  if (isMobile && !standaloneMode && !userCanceledPWA) {
    return (
      <Modal
        onClose={() => {
          setPWAInfo({
            canceledAt: new Date(),
            cancelCount: (PWAInfo.cancelCount || 0) + 1,
          });
        }}
      >
        <div style={{ marginBottom: isIPad() ? '2rem' : '1rem' }}>
          <Typography variant="h3">Get the App!</Typography>
          <div style={{ textAlign: 'center', marginBottom: '2rem' }}>
            <AppIcon src={logo} alt="" />
          </div>
          <Typography>No additional download required.</Typography>
        </div>
        <Typography gutterBottom>With our app you can get quick and easy access on the go!</Typography>
        <Divider />
        {pickInstructions()}
      </Modal>
    );
  }

  return children || null;
};
